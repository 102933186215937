import * as React from "react"
import { graphql } from 'gatsby';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTime } from "luxon";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'

import NotFound from 'pages/404';
import Layout from 'components/templates/Layout';
import Content from "components/templates/Content";
import Link from 'components/link'
import Select from 'components/templates/form/Select';
import useLiveData from 'hooks/useLiveData';

import Pager from 'components/pager';

import { filterEvents } from 'helpers/events';

import EventCard from "components/templates/events/EventCard";

const EventsEventAll = (props) => {
	const { edges: pageEdges } = props.data.allContentPage;
	const { edges: nodes } = props.data.allEventsCard;
	let events = nodes.map(({ node }) => node);
	const pageNodes = pageEdges.map(({ node }) => node);
	let page = pageNodes[0];
	let { pageInfo } = props.data.allEventsCard;
	const { pageContext } = props;

	const { statusText: pageProviderStatusText, response: pageProviderResponse } = useLiveData({
		type: 'ContentPage',
		apiParams: {
			uri: "/events/",
		},
		staticData: props.data,
	});

	if ((!!pageProviderResponse !== false && pageProviderResponse.data.length > 0) && pageProviderStatusText === 'LOADED' && pageContext.liveRefresh !== false) {
		page = pageProviderResponse.data[0];
	}

	const { statusText: providerStatusText, response: providerResponse } = useLiveData({
		type: 'EventsCard',
		staticData: props.data,
		apiParams: { /*_page: props.pageContext.page, _pageSize: props.pageContext.limit,*/ _sort: "firstDate,name", _order: "desc,asc", approved: true, deleted: false, archived: false, _join: "Neighborhood,Category" }
	});

	if ((!!providerResponse !== false && providerResponse.data.length > 0) && providerStatusText === 'LOADED' && pageContext.liveRefresh !== false) {
		events = providerResponse.data;
		pageInfo = {
			hasPreviousPage: (providerResponse.page > 1),
			hasNextPage: (providerResponse.page < providerResponse.pages),
			perPage: providerResponse.pageSize,
			currentPage: providerResponse.page,
			totalCount: providerResponse.total,
			pageCount: providerResponse.pages,
		};
	}

	const neighborhoodOptions = [
		{ label: 'All Neighborhoods', value: '' },
		{ label: 'DeFuniak Springs', value: 'defuniak-springs' },
		{ label: 'Freeport', value: 'freeport' },
		{ label: 'Paxton', value: 'paxton' },
	]
	const now = DateTime.utc().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
	const [displayEvents, setDisplayEvents] = React.useState(filterEvents(events, now, null, null))

	/* 
		If any unhealthy behavior is observed, it is recommended to add the correct variables to the dependency array.
		This however will cause the useEffect to run on every render, which is not ideal.
		http://localhost:8000/events/farmers-market/1715349118957/1715349118957/8AM-12PM/
	*/
	React.useEffect(() => {
		if (providerStatusText === 'LOADED' && pageContext.liveRefresh !== false) {
			setDisplayEvents(filterEvents(events, now, null, null));
		}
	}, [providerStatusText === 'LOADED']/*[providerStatusText, pageContext.liveRefresh, events, now]*/)

	const [startDate, setStartDate] = React.useState(null);
	const [endDate, setEndDate] = React.useState(null);
	const [neighborhood, setNeighborhood] = React.useState(null);
	const [dateSelectorOpen, setDateSelectorOpen] = React.useState(false);
	const [isFilterToday, setIsFilterToday] = React.useState(false);
	const [isFilterWeekly, setIsFilterWeekly] = React.useState(false);
	const [isFilterCustom, setIsFilterCustom] = React.useState(false);

	if (pageInfo.currentPage > pageInfo.pageCount && (providerStatusText === 'LOADED' || pageContext.liveRefresh === false)) {
		return <NotFound />
	}

	const handleStartDateChange = (date) => {
		setStartDate(date);
	}

	const handleEndDateChange = (date) => {
		setEndDate(date);
	}

	const handleNeighborhoodChange = (e) => {
		setNeighborhood(e.target.value);
	}

	const handleDateSelectorClick = (e) => {
		e.preventDefault();
		setDateSelectorOpen(!dateSelectorOpen);
	}

	const handleFilterTodayClick = (e) => {
		e.preventDefault();
		setDateSelectorOpen(false);
		setIsFilterToday(true);
		setIsFilterWeekly(false);
		setIsFilterCustom(false);
		setStartDate(null);
		setEndDate(null);

		const processedEvents = filterEvents(events, now, now, null, false);

		setDisplayEvents(processedEvents)
	}

	const handleFilterWeeklyClick = (e) => {
		e.preventDefault();
		setDateSelectorOpen(false);
		setIsFilterWeekly(true);
		setIsFilterToday(false);
		setIsFilterCustom(false);
		setStartDate(null);
		setEndDate(null);
		const processedEvents = filterEvents(events, null, null, null, true);

		setDisplayEvents(processedEvents)
	}

	const handleApplyFilterClick = (e) => {
		e.preventDefault();
		setDateSelectorOpen(false);
		let searchStartRange = now;
		let searchEndRange = null;
		let searchNeighborhood = null;
		if (startDate) searchStartRange = startDate.toMillis();
		if (endDate) searchEndRange = endDate.toMillis();
		if (neighborhood) searchNeighborhood = neighborhood;

		const filteredEvents = !!searchNeighborhood ? events.filter((event) => {
			return event.Neighborhood.map((neighborhood) => {
				return neighborhood.object.permalink
			}).includes(searchNeighborhood);
		}) : events;

		setIsFilterCustom(true);

		const processedEvents = filterEvents(filteredEvents, searchStartRange, searchEndRange, null);

		setDisplayEvents(processedEvents)
	}

	const handleResetClick = (e) => {
		e.preventDefault();
		setIsFilterToday(false);
		setIsFilterWeekly(false);
		setStartDate(null);
		setEndDate(null);

		setDisplayEvents(filterEvents(events, now, null, null))
	}

	const pattern = ['/events/', '/events/page-$page/'];
	//const [weeklyEvents, setWeeklyEvents] = React.useState(filterEvents(events));

	return (
		<Layout>
			<Content page={page} />
			<section id="event-search" className="event-search">
				<div className="grid-container">
					<div className="grid-x">
						<div className="cell small-12 large-7">
							<div className="date-fieldset">
								<label className="section-label" htmlFor="timeFrame">Browse by Date</label>
								<div className="date-field-container">
									<Link onClick={handleFilterTodayClick} to="#" className="button events-today new-thin">Events Today</Link>
									<Link onClick={handleFilterWeeklyClick} to="#" className="button events-this-week new-thin">Events This Week</Link>
									<div className={`date-wrapper ${dateSelectorOpen ? "open" : ""}`}>
										<div role="button" tabIndex={0} onKeyDown={handleDateSelectorClick} onClick={handleDateSelectorClick} className={`date-selector`}>
											<span>Custom Dates</span>
										</div>
										<div className={`date-options`}>
											<LocalizationProvider dateAdapter={AdapterLuxon}>
												<DatePicker
													slotProps={{
														textField: {
															id: 'startDate',
														}
													}}
													name="startDate"
													disablePast={true}
													label="Start Date"
													onChange={handleStartDateChange}
												/>
												<DatePicker
													slotProps={{
														textField: {
															id: 'endDate',
														}
													}}
													name="endDate"
													disablePast={true}
													label="End Date"
													onChange={handleEndDateChange}
												/>
											</LocalizationProvider>
											<Link onClick={handleApplyFilterClick} to="#" id="date-apply" className="button new-thin submit">Apply</Link>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="cell small-12 large-5">
							<div className="neighborhood-fieldset">
								<label className="section-label" htmlFor="neighborhood">Filter by Neighborhood</label>
								<div className="neighborhood-select-container">
									<Select onChange={handleNeighborhoodChange} hideLabel={true} label="Neighborhood" options={neighborhoodOptions} />
									<Link onClick={handleApplyFilterClick} to="#" className="button new-thin submit">Apply</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="event-search-clearing grid-container">
				<div className="grid-x">
					<div className="cell small-12">
						<div className="search-toggles">
							{isFilterToday && <Link to="#" onClick={handleResetClick}>Viewing &ldquo;Events Today&rdquo;</Link>}
							{isFilterWeekly && <Link to="#" onClick={handleResetClick}>Viewing &ldquo;Events This Week&rdquo;</Link>}
							{((startDate && endDate) && isFilterCustom) && <Link to="#" onClick={handleResetClick}>Viewing Events &ldquo;{new DateTime(startDate).toFormat('MMM d')} &ndash; {new DateTime(endDate).toFormat('MMM d')}&rdquo;</Link>}
							{((startDate && !endDate) && isFilterCustom) && <Link to="#" onClick={handleResetClick}>Viewing Events &ldquo;Starting on {new DateTime(startDate).toFormat('MMM d')}&rdquo;</Link>}
							{((!startDate && endDate) && isFilterCustom) && <Link to="#" onClick={handleResetClick}>Viewing Events &ldquo;Until {new DateTime(endDate).toFormat('MMM d')}&rdquo;</Link>}
						</div>
					</div>
				</div>
			</section>

			<section id="events">
				<div className="grid-container">
					<div className="grid-x grid-margin-x">
						<div className="cell events-flx">
							{displayEvents.length > 0 && displayEvents.map((event, index) => {
								return (
									<EventCard key={`${event.id}-${index}`} event={event} index={index} category={true} />
								)
							})}
						</div>
						{displayEvents.length === 0 && <div className="cell">No events found, please try adjusting your search terms.</div>}
					</div>
				</div>
				<div className="grid-container">
					<div className="grid-x grid-margin-x">
						<div className="cell small-12">
							{(pageInfo.pageCount > 1) && <Pager page={pageInfo.currentPage} pages={pageInfo.pageCount} pattern={pattern} pageSize={pageInfo.perPage} total={pageInfo.totalCount} />}
						</div>
					</div>
				</div>
			</section>

			<section className="banner" id="add-event">
				<div className="grid-container">
					<div className="small-12 cell">
						<a href="/submit-event/"><div className="container">
							<div className="title">Want to add an event?</div>
							<p>From free live music to farmers' market, there is a variety of fun for all in each Authentic Walton neighborhood. Add your event below.</p>
							<div className="button alt">Suggest an Event</div>
						</div></a>
					</div>
				</div>
			</section>
		</Layout>
	)
}

export default EventsEventAll;

export { Head } from 'components/templates/Head';

export const query = graphql`
query eventListQuery {
	allContentPage(
		filter: {uri: {eq: "/events/"}, deleted: {eq: false}, archived: {eq: false}, approved: {eq: true}}
	  ) {
		edges {
		  node {
			pageTitle
			meta
			content {
			  main
			}
			headlineBody
			headlineTitle
			headlineImage
		  }
		}
	}
	allEventsCard(
		sort: {order: [ASC, ASC], fields: [startDate, name]}
		filter: {isCurrentDate: {eq: true}}
	  ) {
		edges {
		  node {
			id
			name
			link
			neighborhood
			category
			actualStartDate
			actualEndDate
			startDate
			time
			endDate
			Category {
			  object {
				id
				permalink
			  }
			}
			Neighborhood {
			  object {
				id
				name
			  }
			}
		  }
		}
		pageInfo {
		  hasNextPage
		  hasPreviousPage
		  perPage
		  currentPage
		  totalCount
		  pageCount
		}
	}
}
`